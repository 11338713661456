import React, { Component } from 'react'

import Layout from '../components/layouts'
export default class Contact extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="m-auto">
          <form
            method="POST"
            name="contact"
            data-netlify="true"
            className="contact-form"
            action="/contact#submitSuccess"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <div />
            <p className="contact-form__text">Contact me</p>
            <label className="contact-form__label">
              NAME<span className="alert-text">*</span>
            </label>
            <input
              type="text"
              name="name"
              className="contact-form__input"
              required
            />
            <label className="contact-form__label">
              EMAIL<span className="alert-text">*</span>
            </label>
            <input
              type="email"
              name="email"
              className="contact-form__input"
              required
            />
            <label className="contact-form__label">
              MESSAGE<span className="alert-text">*</span>
            </label>
            <div className="contact-form__input area">
              <textarea name="message" required></textarea>
            </div>
            <div data-netlify-recaptcha="true"></div>
            <button type="submit" className="btn contact-form__submit">
              SEND
            </button>
          </form>
        </div>
      </Layout>
    )
  }
}
